












import {CategorySale} from "@/request/data-center/CategorySale";

import {Vue, Component} from "vue-property-decorator";

@Component({
    name: 'data_categorySale'
})
export default class data_categorySale extends Vue {
    modal: any = CategorySale;
    search: Array<any> = [
        {
            type: 'DatePicker',
            pickerType: 'month',
            label: '日期',
            key: 'months',
            width: '210px',
            multiple: true,
            require: true,
            placeholder: '请选择日期'
        }
    ];
    columns: Array<any> = [
        {
            title: '日期',
            key: 'month'
        },
        {
            title: '一级类目',
            key: 'categoryName'
        },
        {
            title: '线下销售数量',
            key: 'offlineSaleNum'
        },
        {
            title: '线下销售占比',
            key: 'offlineSaleRate'
        },
        {
            title: '线上销售数量',
            key: 'onlineSaleNum'
        },
        {
            title: '线上销售占比',
            key: 'onlineSaleRate'
        },
        {
            title: '总销售数量',
            key: 'saleNum'
        },
        {
            title: '类目销售占比',
            key: 'saleNumRate'
        }
    ];
}

